<header role="banner" class="header">
  <div class="sidenav-and-logo">
    <ng-container *ngIf="showSidenav">
      <button
        [attr.aria-expanded]="sidenavIsOpen ? 'true' : 'false'"
        class="burger-menu"
        (click)="toggleSidenav()">
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M3 18H21V16H3V18ZM3 13H21V11H3V13ZM3 6V8H21V6H3Z"
            fill="#C40000" />
        </svg>
      </button>
    </ng-container>
    <a [routerLink]="['/home']"
      ><img
        src="../../../../assets/svg/hop-icon.svg"
        alt="{{ 'general.hopLogo' | transloco }}"
    /></a>
    <h1 class="title">{{ 'general.title' | transloco }}</h1>
  </div>
  <ng-container *ngIf="userInfo">
    <div
      tabindex="-1"
      class="profile"
      [matMenuTriggerFor]="menu"
      [attr.aria-label]="'general.profileButton' | transloco">
      <svg
        class="svg-profile"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM7.35 18.5C8.66 17.56 10.26 17 12 17C13.74 17 15.34 17.56 16.65 18.5C15.34 19.44 13.74 20 12 20C10.26 20 8.66 19.44 7.35 18.5ZM18.14 17.12C16.45 15.8 14.32 15 12 15C9.68 15 7.55 15.8 5.86 17.12C4.7 15.73 4 13.95 4 12C4 7.58 7.58 4 12 4C16.42 4 20 7.58 20 12C20 13.95 19.3 15.73 18.14 17.12Z"
          fill="#051039" />
        <path
          d="M12 6C10.07 6 8.5 7.57 8.5 9.5C8.5 11.43 10.07 13 12 13C13.93 13 15.5 11.43 15.5 9.5C15.5 7.57 13.93 6 12 6ZM12 11C11.17 11 10.5 10.33 10.5 9.5C10.5 8.67 11.17 8 12 8C12.83 8 13.5 8.67 13.5 9.5C13.5 10.33 12.83 11 12 11Z"
          fill="#051039" />
      </svg>
      <div class="user-name">{{ userInfo?.name }}</div>
      <svg
        class="svg-arrow"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path d="M7 9.5L12 14.5L17 9.5H7Z" fill="#051039" />
      </svg>
    </div>
    <mat-menu class="test" #menu="matMenu">
      <button mat-menu-item (click)="logout()">
        <mat-icon>power_settings_new</mat-icon>
        <span>{{ 'general.logout' | transloco }}</span>
      </button>
    </mat-menu>
  </ng-container>
  <ng-container *ngIf="!userInfo">
    <mat-menu class="test" #menu="matMenu"> </mat-menu>
  </ng-container>
</header>
