<div class="card-no-result">
  <icon type="no-result-xxl"></icon>
  <div class="container-text">
    <div class="no-result">
      <span>{{
        'mealTray.tableOrders.noResult.noResultFound' | transloco
      }}</span>
    </div>
    <div class="advice">
      <span>{{
        'mealTray.tableOrders.noResult.pleaseBroadenYourSearch' | transloco
      }}</span>
    </div>
  </div>
</div>
