@if (readonly) {
  <div
    (click)="onStartEditing($event)"
    (keydown.space)="onStartEditing($event)"
    [tabIndex]="0"
    class="table-cell-editable">
    {{ rowElement[column] }}
  </div>
} @else {
  <div class="table-cell-editing">
    <mat-form-field appearance="outline">
      <mat-select
        id="supplier-select"
        class="option-field"
        [(ngModel)]="rowElement[column]"
        [cdkTrapFocusAutoCapture]="true"
        [cdkTrapFocus]="true"
        (focus)="onFocusEditing($event)"
        (keyup.escape)="onAbortEditing($event)"
        (selectionChange)="onSave()">
        <mat-option
          *ngFor="let option of options"
          #supplierValue
          [value]="option">
          {{ option }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
}
