import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'logpn-editable-cell-list',
  templateUrl: './editable-cell-list.component.html',
  styleUrl: './editable-cell-list.component.scss',
})
export class EditableCellListComponent {
  @Input() options!: any[];
  @Input() optionLabel!: string;
  @Input() optionFieldValue!: string;

  @Input() readonlyValue: any;
  @Input() readonly: boolean = true;

  @Output() startEditing: EventEmitter<Event> = new EventEmitter();
  @Output() focusEditing: EventEmitter<Event> = new EventEmitter();
  @Output() abortEditing: EventEmitter<Event> = new EventEmitter();
  @Output() save: EventEmitter<Event> = new EventEmitter();

  @Input() rowElement!: any;
  @Input() column!: string;

  onStartEditing($event?: Event) {
    this.startEditing.emit($event);
  }

  onFocusEditing($event: Event) {
    this.focusEditing.emit($event);
  }

  onAbortEditing($event: Event) {
    this.abortEditing.emit($event);
  }

  onSave() {
    this.save.emit();
  }
}
