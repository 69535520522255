import { NgModule } from '@angular/core';
import { IconComponent } from './composants/icon/icon.component';
import { HeaderComponent } from './composants/header/header.component';
import { SidenavComponent } from './composants/sidenav/sidenav.component';
import { LoaderComponent } from './composants/loader/loader.component';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { TranslocoRootModule } from '../transloco-root.module';
import { IMPORTS_MATERIAL } from './material';
import { GenericModalComponent } from './composants/generic-modal/generic-modal.component';
import { NumberCellPipe } from './utils/pipes/number-cell.pipe';
import { TableComponent } from './composants/table/table.component';
import { CdkTrapFocus } from '@angular/cdk/a11y';
import { FormsModule } from '@angular/forms';
import { DynamicPipe } from './utils/pipes/dynamic-pipe.pipe';
import { EditableCellTextComponent } from './composants/table/cells/editable-cell-text/editable-cell-text.component';
import { EditableCellListComponent } from './composants/table/cells/editable-cell-list/editable-cell-list.component';
import { FormatDateTrigramPipe } from './utils/pipes/format-date-trigram.pipe';
import { NoResultComponent } from './composants/no-result/no-result.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    HttpClientModule,
    TranslocoRootModule,
    FormsModule,
    CdkTrapFocus,
    ...IMPORTS_MATERIAL,
  ],
  declarations: [
    HeaderComponent,
    SidenavComponent,
    LoaderComponent,
    IconComponent,
    GenericModalComponent,
    TableComponent,
    EditableCellTextComponent,
    EditableCellListComponent,
    NoResultComponent,
    NumberCellPipe,
    DynamicPipe,
    FormatDateTrigramPipe,
  ],
  exports: [
    HeaderComponent,
    SidenavComponent,
    LoaderComponent,
    IconComponent,
    GenericModalComponent,
    TableComponent,
    EditableCellTextComponent,
    EditableCellListComponent,
    NoResultComponent,
    NumberCellPipe,
    DynamicPipe,
    FormatDateTrigramPipe,
  ],
})
export class SharedModule {}
