import { Component } from '@angular/core';

@Component({
  selector: 'logpn-no-result',
  templateUrl: './no-result.component.html',
  styleUrl: './no-result.component.scss',
})
export class NoResultComponent {
  constructor() {}
}
