export const ALL_SUPPLIERS = 'Tous';

/**
 * Order status for PEQ order type
 */
export enum StatutsOrderMeal {
  Planned = 'PLANNED',
  Sent = 'SENT',
  Order = 'ORDER',
  OrderError = 'ORDER_ERROR',
  RegulatedSend = 'REGULATED_SEND',
  Regulated = 'REGULATED',
  RegulationError = 'REGULATION_ERROR',
}

/**
 * Endpoint list for BFF call
 */
export enum Endpoints {
  SendOrder = '/peq/send-order-by-order-number',
  ReadOrdersByDayAndSupplier = '/peq/read-detail-order-by-day-and-supplier',
  GetSuppliersAndEmailsByCodeAndValidityRange = '/peq/get-suppliers-and-mails',
  getDetailAircraftLoading = '/peq/read-detail-info-on-aircraft-loading',
  NewAircraftLoading = '/peq/add-flight-to-order',
  UpdateAircraftLoading = '/peq/update-flight-data',
  RefreshCalculation = '/peq/update-calcul',
  GenerateOrderSummary = '/peq/generate-order-summary',
  ReadOrdersSummariesFromPeriod = '/peq/read-orders-summaries-from-period',
  CheckIfCalculatingInProgress = '/peq/check-if-calculating-in-progress',
  Compensations = '/ind/compensations',
  CompensationsDetailsByMonth = '/ind/read-compensation-by-month',
}

export enum Modules {
  Hotel = 'hotel',
  MepPlane = 'mep-plane',
  AirportAndRunwayshuttle = 'airport-and-runway-shuttle',
  TaxiAndPickup = 'tax-and-pickup',
  CarRentralAndVDS = 'car-rentral',
  Train = 'train',
  MealTray = 'meal-tray',
  Compensation = 'compensation',
  CalculatingPoint = 'calculating-point',
  History = 'history',
  Settings = 'settings',
}
