@if (readonly) {
  <div
    (click)="onStartEditing($event)"
    (keydown.space)="onStartEditing($event)"
    [tabIndex]="0"
    class="table-cell-editable max-height-two-lines">
    {{ rowElement[column] }}
  </div>
} @else {
  <div class="table-cell-editing commentary">
    <mat-form-field appearance="outline">
      <textarea
        #commentInput
        maxlength="300"
        #comment
        class="input-area"
        matInput
        type="text"
        [cdkTrapFocusAutoCapture]="true"
        [cdkTrapFocus]="true"
        [(ngModel)]="rowElement[column]"
        (focus)="onFocusEditing($event)"
        (keydown.enter)="$event.preventDefault()"
        (keyup.escape)="onAbortEditing($event)"
        (keyup.enter)="onSave($event)"
        cdkTextareaAutosize></textarea>
      <button
        matSuffix
        mat-icon-button
        aria-label="Clear"
        (click)="rowElement[column] = ''">
        <mat-icon>close</mat-icon>
      </button>
      <mat-hint align="start">{{ comment.value.length }} / 300</mat-hint>
    </mat-form-field>
  </div>
}
