import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatDateTrigramme',
})
export class FormatDateTrigramPipe implements PipeTransform {
  transform(dateString: string | null, showYear: boolean = true): string {
    const months = [
      'JAN',
      'FÉV',
      'MAR',
      'AVR',
      'MAI',
      'JUI',
      'JUI',
      'AOÛ',
      'SEP',
      'OCT',
      'NOV',
      'DÉC',
    ];
    if (dateString) {
      const date = new Date(dateString);
      const day = date.getDate().toString().padStart(2, '0');
      const month = months[date.getMonth()];
      const year = date.getFullYear().toString().slice(-2);

      return showYear ? `${day} ${month} ${year}` : `${day} ${month}`;
    } else {
      return '';
    }
  }
}
