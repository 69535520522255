import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'numberCell' })
export class NumberCellPipe implements PipeTransform {
  transform(value: number | null): string {
    if (value == null) {
      return '-';
    }
    return Number(value).toString();
  }
}
