import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ModuleService {
  private readonly enabledModules = new Set(environment.enabledModule);

  isModuleEnabled(moduleName: string): boolean {
    if (this.enabledModules.has('ALL')) {
      return true;
    }
    return this.enabledModules.has(moduleName);
  }
}
