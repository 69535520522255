import {
  AfterViewInit,
  Component,
  ContentChild,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, SortDirection } from '@angular/material/sort';
import { BreakpointObserver } from '@angular/cdk/layout';
import { AbstractComponent } from '../abstract-component';
import { ResponsiveConfig } from '../../../models/responsive-config';
import { takeUntil } from 'rxjs/operators';
import { responsiveBreakpoints } from '../../utils/const/responsive-breakpoints';
import { LoaderService } from '../../services/loader.service';
import { Observable } from 'rxjs';

export interface ColumnDefinition {
  name: string;
  header: string;
  sortable?: boolean;
  cellType?: string;
  isCellCustomized?: boolean;
  headerStyle?: string;
  cellStyle?: string;
  isEditable?: boolean;
  cellStylePipe?: any;
}

@Component({
  selector: 'logpn-table',
  templateUrl: './table.component.html',
  styleUrl: './table.component.scss',
})
export class TableComponent
  extends AbstractComponent
  implements OnInit, AfterViewInit
{
  @Input() responsiveDisplayedColumns?: ResponsiveConfig<string[]>;
  @Output() onClickRow = new EventEmitter<any>();
  isClickableRow: boolean = false;
  @Input() displayPaginator: boolean = true;

  private _displayedColumns!: string[];
  @Input() set displayedColumns(displayedColumns: string[]) {
    this._displayedColumns = displayedColumns;
    if (!this.displayedColumns.includes('actions')) {
      this.displayedColumns.push('actions');
    }
  }

  get displayedColumns(): string[] {
    return this._displayedColumns;
  }

  @Input() columnsDefinition!: ColumnDefinition[];

  @Input() defaultSort?: string;
  @Input() defaultSortDirection: SortDirection = 'asc';

  private _dataSource!: MatTableDataSource<any>;
  @Input() set dataSource(dataSource: MatTableDataSource<any>) {
    this._dataSource = dataSource;
  }

  get dataSource(): MatTableDataSource<any> {
    return this._dataSource;
  }

  @Input() selectedRow?: any;

  // ContentChild for custom cells template
  @ContentChild('customCells', { static: false })
  customCellsTemplateRef!: TemplateRef<any>;

  // ContentChild for action template
  @ContentChild('actions', { static: false })
  actionsTemplateRef!: TemplateRef<any>;

  // ContentChild for footer template
  @ContentChild('footer', { static: false })
  footerTemplateRef!: TemplateRef<any>;

  // ViewChild for pagination and sorting
  private paginator!: MatPaginator;

  @ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {
    this.paginator = mp;
    this.dataSource.paginator = this.paginator;
  }

  private sort!: MatSort;

  @ViewChild(MatSort) set matSort(ms: MatSort) {
    this.sort = ms;
    this.dataSource.sort = this.sort;
  }

  protected loading$!: Observable<boolean>;

  constructor(
    private breakpointObserver: BreakpointObserver,
    private readonly loaderService: LoaderService
  ) {
    super();
  }

  ngOnInit() {
    this.breakpointObserver
      .observe([
        responsiveBreakpoints.small,
        responsiveBreakpoints.medium,
        responsiveBreakpoints.large,
      ])
      .pipe(takeUntil(this.destroy$))
      .subscribe(state => {
        if (this.responsiveDisplayedColumns) {
          if (state.breakpoints[responsiveBreakpoints.small]) {
            this.displayedColumns = this.responsiveDisplayedColumns?.small;
          } else if (state.breakpoints[responsiveBreakpoints.medium]) {
            this.displayedColumns = this.responsiveDisplayedColumns?.medium;
          } else if (state.breakpoints[responsiveBreakpoints.large]) {
            this.displayedColumns = this.responsiveDisplayedColumns?.large;
          }
        }
      });
    this.checkClickableRow();

    this.loading$ = this.loaderService.loaderIsVisible;
  }

  checkClickableRow(): void {
    this.isClickableRow = this.onClickRow.observed;
  }

  //reset paginator to first page
  public firstPage() {
    if (this.paginator) {
      this.paginator.firstPage();
    }
  }

  ngAfterViewInit(): void {
    if (this.defaultSort != null && this.dataSource?.sort) {
      this.dataSource.sort.active = this.defaultSort;
      this.dataSource.sort.direction = this.defaultSortDirection;
    }
  }

  emitRowData(index: number) {
    this.onClickRow.emit(this.dataSource.data[index]);
  }

  onKeyDownHandler(event: KeyboardEvent, index: number) {
    if (event.key === 'Enter') {
      this.emitRowData(index);
    }
  }
}
