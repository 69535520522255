<h2 class="title" mat-dialog-title>{{ data.title }}</h2>
<mat-dialog-content class="content">
  <p [innerHTML]="data.content"></p>
</mat-dialog-content>
<mat-dialog-actions align="end">
    @if (data.leftButtonText ) {
      <button class="cancel-button" mat-button (click)="onNoClick()">
        {{ data.leftButtonText }}
      </button>
    }
    @if (data.rightButtonText) {
      <button class="validate-button" mat-button (click)="onConfirmClick()">
        {{ data.rightButtonText }}
      </button>
    }
</mat-dialog-actions>
