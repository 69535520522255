import { Component } from '@angular/core';
import { Subscription } from 'rxjs';
import { SidenavService } from '../../services/sidenav.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ModuleService } from '../../../core/services/module.service';
import { Modules } from '../../utils/logistique-pn';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrl: './sidenav.component.scss',
})
export class SidenavComponent {
  selectedMenuItem: string | null = 'home';
  isSidenavOpen: boolean = true;
  private sidenavSubscription: Subscription;

  constructor(
    private sidenavService: SidenavService,
    private route: ActivatedRoute,
    private router: Router,
    protected moduleService: ModuleService
  ) {
    const currentUrl = this.router.url;
    const segments = currentUrl.split('/');
    if (segments.length > 1) {
      this.selectedMenuItem = segments[1];
    }
    this.sidenavSubscription = this.sidenavService.isSidenavOpen$.subscribe(
      isOpen => {
        this.isSidenavOpen = isOpen;
      }
    );
  }

  ngOnInit() {
    const currentUrl = this.router.url;
    const segments = currentUrl.split('/');
    if (segments.length > 1) {
      this.selectedMenuItem = segments[1];
    }
  }

  ngOnDestroy() {
    this.sidenavSubscription.unsubscribe();
  }

  selectMenuItem(menuItem: string) {
    this.selectedMenuItem = menuItem;
    this.router.navigate([menuItem], { relativeTo: this.route.parent });
  }

  protected readonly Modules = Modules;
  protected readonly History = History;
}
